<template>
    <div class="user-box">
        <vue-header :fixed="true" title="泉脉" />
        <div class="user-header flex flex-y" v-if="info">
            <div class="flex">
                <div class="user-header-img">
                    <img :src="info.avatarUrl" v-if="info.avatarUrl"/>
                    <img v-else src="../../../../images/user.png"/>
                </div>
                <div class="flex flex-y flex-1">
                    <div>
                        <div class="user-header-name">{{info.name||info.nikeName}}</div>
                        <div class="user-header-iphone">{{info.phone}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="user-body" v-if="info && info.amount_id">
            <div class="user-body-li flex">
                <router-link :to="`/redEnvelope/list`" class="b-li flex-1">
                    <img src="../../../../images/redEnvelope/test-own.png"/>
                    <div>健康评估</div>
                </router-link>
                <a href="/redEnvelope/send" class="b-li flex-1">
                    <img src="../../../../images/redEnvelope/test-red.png"/>
                    <div>健康红包</div>
                </a>
                <a href="/redEnvelope/manage" class="b-li flex-1">
                    <img src="../../../../images/redEnvelope/test-detail.png"/>
                    <div>红包领取记录</div>
                </a>
            </div>
            <div class="user-body-li flex">
                <router-link :to="`/profile?red=true`" class="b-li flex-1">
                    <img src="../../../../images/redEnvelope/mycard.png"/>
                    <div>我的名片</div>
                </router-link >
                <div class="flex-1"></div>
                <div class="b-li flex-1" @click="telFun">
                    <img src="../../../../images/redEnvelope/tel.png"/>
                    <div>联系客服</div>
                </div>
            </div>
        </div>
        <div class="user-body" v-else-if="info && !info.amount_id">
            <div class="user-body-li flex">
                <a href="/redEnvelope/reportList" class="b-li flex-1">
                    <img src="../../../../images/redEnvelope/test-own.png"/>
                    <div>健康评估</div>
                </a>
                <a href="/redEnvelope/manage" class="b-li flex-1">
                    <img src="../../../../images/redEnvelope/test-red.png"/>
                    <div>健康红包</div>
                </a>
                <div class="b-li flex-1" @click="telFun">
                    <img src="../../../../images/redEnvelope/tel.png"/>
                    <div>联系客服</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import vueHeader from '@/components/header';
    import { mapState, mapActions, mapMutations } from "vuex";
    import { Dialog } from 'vant';
    export default {
        name: 'user2',
        data(){
            return {
                
            }
        },
        components:{
            vueHeader
        },
        computed:{
            ...mapState('profile',['info']),
        },
        created:function(){
            this.axiosMain({});
        },
        methods: {
            telFun(){
                Dialog.alert({
                    title: '联系客服',
                    message: '客服服务时间:周一至周五 9:00-17:30</br>客服电话:<a href="tel:0571-87703558">0571-87703558</a>',
                });
            },
            ...mapActions('profile',['axiosMain']),
        }
    }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'user';
</style>